import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setChatCount, /*useGetChatUserQuery, useGetUserFromChatQuery */ } from '../../features/ChatSlice';
//import { skipToken } from '@reduxjs/toolkit/query';
//import { ChatEngine, getOrCreateChat } from 'react-chat-engine'
import { CircularProgressCentered } from '../../app/sharedcomponents';
//import { Calculate } from '@mui/icons-material';
//import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
//import { isFetchBaseQueryError, isErrorWithMessage } from './features/profilesSlice';
//import { useAuthProfile } from "../../app/hooks"
//import {DirectChat} from "./DirectChat"
//import { useMultiChatLogic, MultiChatSocket, MultiChatWindow, /*ChatFeedProps, ChatListProps, ChatCardProps, useSingleChatLogic, ChatFeed, SingleChatSocket, MessageListProps, ChatHeaderProps, ChatList*/ } from "react-chat-engine-advanced";
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import Stack from '@mui/material/Stack';

//import { getAIProfileMessageSummary, getAIProfileMessageSummaryOrgetAIProfileSummaryOrgetProfileFromIDAndGenerateAIProfileSummary, getOrGenerateAIProfileSummary } from "../../features/dataProcessingSlice"

import { Container } from '@mui/material';
import { ProfileForMessaging } from './AIProfileForMessaging';
import { NoChatsMessage } from './NoChatsMessage';
import { GroupChannelList } from '@sendbird/uikit-react/GroupChannelList';
import { useGroupChannelListContext, GroupChannelListProvider, ChannelListQueryParamsType } from '@sendbird/uikit-react/GroupChannelList/context';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { chatColors, SendbirdChatButton, SendbirdChatButtonProps, SendbirdDirectChat } from './SendbirdChatButton';
import GroupChannel from '@sendbird/uikit-react/GroupChannel';
import { ChatHeaderMM, ChatListHeaderMM, ChatListPreview, PlaceHolderEmptyList } from './ChatHeaderMM';
import { showDirectChat, selectChat } from '../../features/dialogSlice';
import { GroupChannelListQueryParamsInternal } from '@sendbird/uikit-react/ChannelList/context';
import { setPublicPinClicked, setAiProfileOptions, PublicPinClicked, setLeftSideComponentWithOptions, LeftSideComponentWithOptions } from '../../features/dataProcessingSlice';
import { AIProfile } from "../AI/AIProfile";
import { useAIProfileForPinClicked } from "../AI/AIhook";

//import { AILoadingForMessaging } from './AILoadingForMessaging';
//import { ChatCountValueAndSet } from './ChatMain';
//import { ChatCardMM } from './ChatCardMM';
//import { ChatHeaderMM } from './ChatHeaderMM';
//import { ConfirmChatDelete } from './ConfirmChatDelete';

import './sendbird.css';
import { RootState, useTypedSelector } from '../../app/store';
import { AISummary, AISummaryComponentProps } from '../AI/AISummary';
import { AnalyticsChannelSummary, ChatUserNotFound, SendbirdChannelDataBugWorkaround } from './ChatCardMM';

export interface ChatsProps {
    //projectId: string, 
    //username: string,
    //secret: string,
    chatAndUserData?: any,
    //chat: any,
    //createdChatId: number,
    height: number,
    id: number,//refactor
    direct_chat: boolean
    //chatCountValueSet: ChatCountValueAndSet
}

export const GetOtherPerson: (chat: any, id: number) => any = (chat, id) => {//refactorexport const GetOtherPerson: (chat: any, iam_id: string) => any = (chat, iam_id) => {
    if (!id) {//refactor
        return undefined
    }

    const otherPerson = chat?.members?.find((member: any) => {
        return Number(member.metaData?.id) != id
    })
    return otherPerson
}

export interface SelectedChat { chatId: number, messageTo: number, username: string }//refactorexport interface SelectedChat { chatId: number, messageTo: string, username: string }


// export const createChannelListQuery = ({
//     sdk,
//     userFilledChannelListQuery = {},
//   }: ChannelListQueryParamsType): GroupChannelListQuery => {
//     const params: GroupChannelListQueryParamsInternal = {
//       includeEmpty: false,
//       limit: 20, // The value of pagination limit could be set up to 100.
//       //order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
//     };

//     if (userFilledChannelListQuery) {
//       Object.keys(userFilledChannelListQuery).forEach((key) => {
//         // @ts-ignore
//         params[key] = userFilledChannelListQuery[key];
//       });
//     }

//     return sdk.groupChannel.createMyGroupChannelListQuery(params as GroupChannelListQueryParams);
//   };

export const Chats: React.FC<ChatsProps> = ({ chatAndUserData, height, id: loggedInId, direct_chat }) => {
    const { showChat, appId, channelUrl, lastChat } = chatAndUserData
    const url: string = chatAndUserData?.chat?.channel_url
    const { id: userId, secret1: accessToken, username } = chatAndUserData.userCreationResults
    const [leftSideComponent, setLeftSideComponent] = React.useState<React.ReactNode>(<></>)
    const [currentChannelUrl, setCurrentChannelUrl] = React.useState<string>()
    const dispatch = useDispatch()
    const chatState = useSelector((state: any) => state.dialog);

    const publicPinClickedAIState = useSelector((state: any) => state.dataProcessing.publicPinClicked)
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => {
        // const chatState: SendbirdChatButtonProps = { showChat: true, appId: appId, userId: userId, channelUrl: url, accessToken: accessToken }
        // dispatch(showDirectChat(chatState))
        //setCurrentChannelUrl( url)
    }, [])

    // const channelListQueryParams = {
    //     includeEmpty: true, // This includes channels with no messages
    //   };

    //<AIProfile profileToShow={profileToUse} AIProfileSummary={AIProfileSummary} showMessageButton={false} showViewUserStoryAnalytics={false} chatMode={true} />
    //<ChatAISummaryWrapper />
    const setChatLeftSide: (channel: any) => void = (channel) => {
        if (channel) {
            setCurrentChannelUrl(channel?.url)
            const otherPerson = GetOtherPerson(channel, loggedInId)
            dispatch(selectChat())
            if (channel.isSuper) {
                //const channelData = (typeof channel.data === 'string') ? SendbirdChannelDataBugWorkaround(channel.data) : channel.data;
                const channelData =  SendbirdChannelDataBugWorkaround(channel.data) ;
                const memberCount = [<>{channel?.memberCount && <>There are {`${channel.memberCount}`} members in this chat</>}</>]
                var renderMetadataList: React.ReactElement[] = []
                var title: string
                switch (channelData?.mmChannelType) {
                    case 'resource-query':
                        title = "Members with Shared Interests"
                        renderMetadataList = [<>Chat for Members interested in <Typography variant="h7" sx={{ color: '#656EFF', }}>{`${channel.name}`}</Typography></>,
                        ...memberCount]

                        break;
                    case 'analytics-users':
                        title = 'Member Data Analytics'
                        renderMetadataList = [<>Includes members with  <Typography variant="h7" sx={{ color: '#656EFF', }}>{`${channelData?.formatted_variable}`}</Typography> similar to <Typography variant="h7" sx={{ color: '#656EFF', }}>{`${channelData?.value}`}</Typography></>,
                        ...memberCount]
                        break
                    default:
                        title = "Info not avaiable"
                        renderMetadataList = [<><Typography variant="h7" sx={{ color: '#656EFF', }}>Sorry, This chat info is not available</Typography></>]
                }

                setLeftSideComponent(<AnalyticsChannelSummary title={title} channel={channel} chatMode={true} renderMetadataList={() => renderMetadataList}
                />)
            } else if (otherPerson) {
                //dispatch(setPublicPinClicked({ id: otherPerson.metaData.id, aiProfileOptions: { showMessageButton: false, showViewUserStoryAnalytics: false, showAccountPromotion: false } }))
                setLeftSideComponent(<AISummary bottomSheetRef={null} pinToUse={Number(otherPerson.metaData.id)} showAccountPromotion={false} showMessageButton={false} showViewUserStoryAnalytics={true} chatMode={true} ><ChatUserNotFound channel={channel} chatMode={true} reason='This member may have chosen to leave Patient Connect' /></AISummary>)
            } else {
                //dispatch(setLeftSideComponentWithOptions({ component: 'ChatUserNotFound' }))
                setLeftSideComponent(<ChatUserNotFound channel={channel} chatMode={true} reason={'This member may have chosen to leave this chat'} />)
            }
        } else {
            //setCurrentChannelUrl(url)
        }
    }
    return (
        <Stack className="messages" direction="row" sx={{ height: '100%', width: '100%', display: 'flex', backgroundColor: 'white' }}>
            <Box sx={{
                backgroundColor: 'white',
                overflow: 'auto',
                margin: 'auto',
                height: '100svh',
                pb: 2,
                width: {xs:'100%',sm:'100%',md:'350px',lg:'450px'},
                display: { xs: chatState.showChatProfile, sm: chatState.showChatProfile, md: 'flex' },
            }}
            >
                <Box sx={{ flex: 2, display: { xs: chatState.showChatProfile, sm: chatState.showChatProfile, md: 'flex' }}}>
                    {leftSideComponent}
                </Box>
            </Box>
            <Box sx={{ minWidth: '300px', flex: 2, display: { xs: chatState.showChatConvo, sm: chatState.showChatConvo, md: 'flex' } }} className="sendbird-app__conversation-wrap">
                <Box className="custom-channel-list" style={{ height: '100%', width: '100%' }}>
                    <GroupChannel channelUrl={currentChannelUrl!} renderChannelHeader={() => {
                        return (
                            <ChatHeaderMM openSummary={() => { return }} showCloseMd={false} />
                        )
                    }} />
                </Box>
            </Box>
            <Box sx={{ flex: 1, backgroundColor: 'white', width: '100%', display: { xs: chatState.showChatList, sm: chatState.showChatList, md: 'flex' } }} className="sendbird-app__channellist-wrap">
                <Box className="custom-channel-list" sx={{ overflow: 'auto', height: '99svh', width: '100%', pb: 10, backgroundColor: 'white' }}>
                    <GroupChannelList
                        selectedChannelUrl={currentChannelUrl}
                        onChannelCreated={(channel) => {
                            setCurrentChannelUrl(channel.url)
                        }}
                        onChannelSelect={(channel) => setChatLeftSide(channel)}
                        renderHeader={() => {
                            return (
                                <Box sx={{ height: '100%' }}>
                                    <ChatListHeaderMM setChannel={(channel) => {
                                        setCurrentChannelUrl(channel?.url)
                                        const otherPerson = GetOtherPerson(channel, loggedInId)
                                        dispatch(selectChat())
                                        // if (otherPerson) {
                                        //     dispatch(setPublicPinClicked({ id: otherPerson.metaData.id, aiProfileOptions: { showMessageButton: false, showViewUserStoryAnalytics: false, showAccountPromotion: false } }))
                                        // }
                                        setChatLeftSide(channel)
                                    }} url={url} expectedUserChatCount={chatAndUserData.expectedUserChatCount} />
                                </Box>
                            )
                        }}
                        disableAutoSelect={!!url}
                        channelListQueryParams={{
                            includeEmpty: true, // This includes channels with no messages
                            //limit: 100
                        }}
                        renderPlaceHolderEmptyList={(channel) => { return (< PlaceHolderEmptyList expectedUserChatCount={chatAndUserData.expectedUserChatCount} />) }}
                    />
                </Box>
            </Box>
        </Stack>
    )
}





// export const ChatAISummaryWrapper: React.FC = () => {
//     const dispatch = useDispatch<any>();
//     //const { authStatus, profile: loggedInProfile, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
//     const AIState_publicPinClicked = useTypedSelector((state: RootState) => state.dataProcessing.publicPinClicked)


//     const leftSideComponentWithOptionsState: LeftSideComponentWithOptions = useTypedSelector(
//         (state: RootState) => state.dataProcessing.leftSideComponentWithOptions
//     );
//     if (leftSideComponentWithOptionsState.component === 'ChatUserNotFound') {
//         return (<ChatUserNotFound />)
//     } else
//         if (leftSideComponentWithOptionsState.component === 'AnalyticsChannelSummary') {
//             return (<AnalyticsChannelSummary />)
//         } else if (leftSideComponentWithOptionsState.component === 'Profile') {
//             return (<AISummary showAccountPromotion={false} showMessageButton={false} showViewUserStoryAnalytics={true} chatMode={true} />)
//         }
//     return (<></>)
// }