import React, { useEffect, useState } from "react";
import { Avatar, Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import { ReactComponent as CaregiverSVG } from '../assets/caregiver.svg';
import { ReactComponent as MMWarriorSVG } from '../assets/patienticon.svg';
import { ReactComponent as MedicSVG } from '../assets/medic.svg';
import { ReactComponent as OtherSVG } from '../assets/other.svg';
import { ReactComponent as CareCenterSVG } from '../assets/carecentericon.svg';
import { ReactComponent as HousingSVG } from '../assets/house.svg';
import { ReactComponent as CoeSVG } from '../assets/coe.svg';
import { ReactComponent as SupportGroupSVG } from '../assets/support_group.svg';

// import CaregiverSVG2 from '../assets/caregiver.svg';
// import MMWarriorSVG2 from '../assets/patienticon.svg';
// import MedicSVG2 from '../assets/medic.svg';


import PlaceIcon from '@mui/icons-material/Place';
import { GetPinName } from './map/MapFilter';
import Button from '@mui/material/Button';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
//import { setClickedMessageIAM } from "../features/dataProcessingSlice";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { themePolygon } from '../app/theme';
import { useAuthProfile } from "../app/hooks"
import { chatApi, DirectChatUsers, useSetChatUserMutation } from '../features/ChatSlice';
import { CircularProgressCentered } from "../app/sharedcomponents";

import LoadingButton from '@mui/lab/LoadingButton';
import { IsProdChatUserInTest, prodChatUserInTestMessage } from "./messaging/ChatMain";
import { GetUserTypePinPNG, StartChat } from "./markers/MMMarkers";

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import { useUpdateUsernameMutation } from '../features/profilesSlice';
import { GetIntitutionTypePinPNG, MarkerTypeOption } from "./markers/InstitutionMarkers";

export const pinIconStyle = { width: '26px', height: '26px' }


export const GetUserTypePin = (name: string, props: any = {}) => GetPinByCategoryAndType("User", name, props)

export type PinCategory = "Institution" | "User";
interface PatientPNGComponentProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  // You can add custom props if needed, but this will allow all img props
  pinCategory: PinCategory
  id: string
}
export const PinPNGComponent: React.FC<PatientPNGComponentProps> = (props) => {

  let pinPNG = props.pinCategory === 'User' ? GetUserTypePinPNG(props.id) : //'P')
    GetIntitutionTypePinPNG(props.id)
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <img src={pinPNG as string} {...props} />
    </Box>
  );
};




export const GetPinByCategoryAndType = (category: PinCategory, markerTypeVsMarkerTypeOption: string | MarkerTypeOption, props: any = {}) => {
  const { type: markerType, status: option } = typeof markerTypeVsMarkerTypeOption === 'object' ? markerTypeVsMarkerTypeOption : { type: markerTypeVsMarkerTypeOption, status: undefined }
  switch (category) {
    case "Institution":
      switch (markerType) {
        case "E":
          return { icon: <CoeSVG {...props} />, color: '#FFEDED', namePlural: "Treatment Center(s)" }
        case "C":
          return { icon: <CareCenterSVG {...props} />, color: '#FFF5D1', namePlural: "Care Center(s)" }
        case "H":
          return { icon: <HousingSVG {...props} />, color: '#E0EEED', namePlural: "Patient Lodging" }
        case "S":
          return { icon: <SupportGroupSVG {...props} />, color: '#E0EEED', namePlural: "Support Group(s)" }
        case "BSTP":
          return { icon: <PinPNGComponent {...props} pinCategory="Institution" id="BSTP" />, color: 'rgb(206,250,255)', namePlural: "Wheeling for Healing" }
        default:
          return { icon: <OtherSVG {...props} />, color: '#FFEDED' }
      }
      break;

    case "User":
      switch (markerType) {
        case "P":
          //return { icon: <MMWarriorSVG {...props} />, color: '#FFEDED', namePlural: "Patient(s)" }
          return { icon: <PinPNGComponent {...props} pinCategory="User" id="P" />, color: '#FFEDED', namePlural: "Patient(s)" }
        case "C":
          return { icon: <CaregiverSVG {...props} />, color: '#FFF5D1', namePlural: "Caregiver(s)" }
        case "M":
          return { icon: <MedicSVG {...props} />, color: '#E0EEED', namePlural: "Health Care Professional(s)" }
        case "O":
          return { icon: <OtherSVG {...props} />, color: '#E7E7E7', namePlural: "Other" }
        default:
          return { icon: <OtherSVG {...props} />, color: '#E7E7E7' }
      }
  }
}


export const PinChip: React.FC<{ category: PinCategory, type: string, size: any }> = ({ category, type, size }) => {
  const pinIconStyle = { width: '28px', height: '28px' }; // Adjust the size as needed
  //const userPinAndBackgroundColor = GetUserTypePin(type, { style: iconStyle })
  const pinData = GetPinByCategoryAndType(category, type, { style: pinIconStyle })
  //console.log(pinData.icon)
  //console.log(size)
  return (
    <Chip
      label={pinData.namePlural?.replace("(s)", "")}
      size={size}
      icon={pinData.icon}
      sx={{ pt: 2, pb: 2, width: 'fit-content', backgroundColor: pinData.color, }}
    />
  )
}

//export StartDirectChat( { messageTo: profile.iam_id, id: profile.id })

export const MessageProfile: React.FC<{ profile: any, showMessageButton?: boolean, onEdit?: () => void, renderUserName?: () => JSX.Element, hideUserAndLocationPinsForIncompleteProfile?: boolean }> = ({ renderUserName, onEdit, profile, showMessageButton = true, hideUserAndLocationPinsForIncompleteProfile }) => {
  const dispatch = useDispatch<any>();
  const profile_iam = useSelector((state: any) => state.dataProcessing.publicPinProfile)
  const navigate = useNavigate()
  const { authStatus, profile: profileLoggedIn, isLoading: isLoadingpProfileLoggedIn, isSuccess, isError, error, userAttributesStateStatus, messagingReady } = useAuthProfile()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, error: errorSetChat, data: setChatData, reset }] = useSetChatUserMutation()
  //const [editUsername, setEditUsername] = useState(false)
  //const [errorUsername, setErrorUsername] = useState('')
  //const [usernameText, setUsernameText] = useState('')
  //const [SetUsername, { isLoading: isLoadingSetUsername, isSuccess: isSuccessSetUsername, error: errorSetUsername, data: setUsernameData }] = useUpdateUsernameMutation()

  const startMessage: () => void = () => {
    if (!isSuccess)
      return
    StartChat([profileLoggedIn.id, profile.id], authStatus, () => navigate("/Messages"), (setChatArg: DirectChatUsers) => SetChat(setChatArg))
  }

  // async function handleEditUsername() {
  //   if (usernameText.length == 0) {
  //     setEditUsername(false)
  //     return
  //   }

  //   if (usernameText.length >= 80) {
  //     setErrorUsername('Please enter a username smaller than 80 characters')
  //     return
  //   }

  //   setErrorUsername('')
  //   const result = await SetUsername({ id: profileLoggedIn.id, username: usernameText })
  //   if (result.data.message == 'Username updated successfully') {
  //     setEditUsername(false)
  //     //window.location.reload()
  //     refetchProfile();
  //   } else if (result.data.message == 'Username is already taken') {
  //     setErrorUsername(result.data.message)
  //   } else {
  //     setErrorUsername('Something went wrong')
  //   }
  // }

  //   (profile.id == profileLoggedIn.id && onEdit) && <IconButton aria-label="delete" onClick={()=>{setEditUsername(true)}}>
  //   <EditIcon />
  // </IconButton>
  // }
  useEffect(() => {
    if (isSuccessSetChat) {
      //console.log("setChatData", setChatData)
      // if( !messagingReady)
      //   dispatch(chatApi.util.invalidateTags(['Ready']))
      navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
  }, [isSuccessSetChat])

  if (isError) {
    const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
    return <div>{errMsg}</div>;
  }
  const showUserAndLocationPins = (hideUserAndLocationPinsForIncompleteProfile && profileLoggedIn.is_completed) || !hideUserAndLocationPinsForIncompleteProfile
  const userName = renderUserName ? renderUserName() : <Typography variant="h6">{profile.name_public}</Typography>
  return (
    <Box sx={{ border: 1, borderRadius: 3, borderColor: '#C3C6FF', pt: 3, pb: 3, pr: 1, pl: 1, mb: 2, mt: 2 }}>
      <Stack display='flex' direction="row" alignItems="center" gap={1} sx={{}}>
        <Box flexGrow='10' display='flex' flexDirection="row" justifyContent='flex-end' >
          <Avatar sx={{ bgcolor: '#656EFF', height: 50, width: 50, }} />
        </Box>
        <Stack flexGrow='10' direction="column" alignItems="stretch" gap={1} >
          <Stack direction="row" sx={{ display: 'flex', width: '100%', alignItems: 'flex-end' }}>
            {userName}
            {(profile.id == profileLoggedIn.id && onEdit) && <IconButton aria-label="delete" onClick={() => onEdit()}>
              <EditIcon />
            </IconButton>
            }
          </Stack>
          {showUserAndLocationPins && <PinChip category="User" type={profile.type} size="medium" />}
          {showUserAndLocationPins && <Stack direction="row" alignItems="left" gap={0}>
            <PlaceIcon sx={{ color: '#656EFF' }} />
            <Typography variant="body1" sx={{ color: '#656EFF' }}>
              {profile.formattedsearchterm}
            </Typography>
          </Stack>}
        </Stack>
      </Stack>
      {(showMessageButton && profileLoggedIn.id != profile.id) && <>
        <LoadingButton id="profile_message_button" loadingPosition="start" loading={isLoadingSetChat} onClick={startMessage} variant="contained" disableElevation startIcon={<SmsOutlinedIcon />} sx={{ mt: 1, backgroundColor: '#656EFF', width: '100%' }}>
          Message
        </LoadingButton>
        {(isLoadingSetChat || isLoadingpProfileLoggedIn) && <CircularProgressCentered />}
      </>
      }

    </Box>
  )
}

