import React, { useCallback, useState } from "react";
import { GoogleMap, Marker, DirectionsRenderer, useLoadScript } from "@react-google-maps/api";
import bikeStops, { BikeStop } from "../resources/BikeStops";

interface Point {
  lat: number;
  lng: number;
  title: string;
}

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};


export interface useBikeRouteInterface {
  directions: google.maps.DirectionsResult | null,
  points: Point[],
  bikeStops: BikeStop[]
  routePathPastCurrent: google.maps.LatLngLiteral[]
  routePathFuture: google.maps.LatLngLiteral[]
  error: string | undefined
}


export const useBikeRoute = (calculate: boolean): useBikeRouteInterface => {
  const [points, setPoints] = useState<Point[]>([])
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
  const [routePathPastCurrent, setRoutePathPastCurrent] = useState<google.maps.LatLngLiteral[]>([]);
  const [routePathFuture, setRoutePathFuture] = useState<google.maps.LatLngLiteral[]>([]);
  //  const [routePath, setRoutePath] =  useState<{pathPastCurrent: pathFuture:google.maps.LatLngLiteral[],  pathFuture:google.maps.LatLngLiteral[]}>({pathPastCurrent:[], pathFuture:[]});
  const [routePath, setRoutePath] =  useState<{pathFuture:google.maps.LatLngLiteral[], pathPastCurrent: google.maps.LatLngLiteral[]}>({pathFuture:[], pathPastCurrent: []});
  const [error, setError] = useState<string | undefined>()

  const calculateRoute = useCallback(() => {
    if (!calculate)
      return
    //const points: Point[] = bikeStops.map( (bikeStop, index) =>  ({ lat: bikeStop.lat, lng: bikeStop.lng, title:`Wheeling for Healing Stop ${index}` }))

    const pointsObject = bikeStops.reduce(
      (pointArrays, bikeStop, index) => {
        const point: Point = { lat: bikeStop.lat, lng: bikeStop.lng, title: `Wheeling for Healing Stop ${index}` }
        switch (bikeStop.type.status) {
          case 'C':
            pointArrays.pointsFuture.push(point);
            pointArrays.pointsPastCurrent.push(point)
            break
          case 'F':
            pointArrays.pointsFuture.push(point);
            break
          default:
            pointArrays.pointsPastCurrent.push(point)

        }
        return pointArrays
      },
      {pointsPastCurrent:[], pointsFuture:[]} as { pointsPastCurrent: Point[], pointsFuture: Point[] } // Initialize two empty arrays for passing and failing elements
    );

    const directionsService = new google.maps.DirectionsService();

    const getWwayPoints: (points: Point[], setFunc: (p: google.maps.LatLngLiteral[]) => void) => any = (points, setFunc) => {
      if (points.length < 2)
        return []
      const waypoints = points.slice(1, -1).map((point) => ({
        location: { lat: point.lat, lng: point.lng },
        stopover: true,
      }));
      directionsService.route(
        {
          origin: points[0],
          destination: points[points.length - 1],
          waypoints,
          travelMode: google.maps.TravelMode.BICYCLING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result) {
            setDirections(result);
            const path = result.routes[0].overview_path.map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }));

            setFunc(path)
            setPoints( ( pts: Point[]) => [...pts, ...points])          
            //setPoints( points)
          } else {
            const error = `Error fetching directions: ${status}`
            console.error(error);
            setError(error)            
          }
        }
      );
    }
     getWwayPoints(pointsObject.pointsPastCurrent, setRoutePathPastCurrent)
     getWwayPoints(pointsObject.pointsFuture, setRoutePathFuture)

  }, [bikeStops]);

  React.useEffect(() => {
    calculateRoute();
  }, [calculateRoute]);


  return {
    routePathPastCurrent, routePathFuture, directions, points, bikeStops, error
  }

}



