import React, { useState } from "react"
//import { Avatar } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { TextField, Typography, IconButton, InputAdornment, Button } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
//import { Auth } from 'aws-amplify'
//import { Auth } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";
import { LoadingButton } from '@mui/lab';
//import { Hub } from "aws-amplify/utils";
import { signIn, signOut, getCurrentUser, signInWithRedirect, resendSignUpCode } from "aws-amplify/auth";
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg'
import { HeaderContent } from '../../app/sharedcomponents';
import Divider from '@mui/material/Divider';
import { useForm, Controller, useFormContext, FormProvider } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
//import awsConfig from '../../../src/aws-exports';
import Stack from '@mui/material/Stack';
import { themePolygon } from "../../app/theme";
import { SignInWithFB } from "./utils";
import { getAwsConfigForBase } from "../..";
import { profileApi } from "../../features/profilesSlice";
import { store } from "../../app/store";

export interface LoginErrorInfo { type: string, message: string, status: string }




export const LoginError: React.FC<{ errorInfo?: LoginErrorInfo }> = ({ errorInfo }) => {
  if (!errorInfo)
    return null;
  if (errorInfo?.message) {
    return (
      <Typography color={errorInfo?.status ?? "error"}>{errorInfo.message}</Typography>
    );
  }
  else {
    return (
      <Typography color={errorInfo?.status ?? "error"}>Error Occurred</Typography>
    );
  }
}




//const defaultTheme = createTheme();  using themePolygon

interface DeleteProfileProps { deleteProfile?: boolean }

export interface FormValuesEmailSignIn {
  email: string;
  password: string
  // apiMessage: string
  // emailNotConfirmed: string
  // emailSignIn: string
}

//<CssBaseline />
//        <Authenticator.Provider></Authenticator.Provider>
export const SignInEmail: React.FC<DeleteProfileProps> = ({ deleteProfile }) => {
  return (
    <Container component="main" maxWidth="sm"  sx={{height:'100%', pt:4, pb:4}}>
      <SignInUIEmail deleteProfile={deleteProfile} />
    </Container>
  );
}
// </Authenticator.Provider>

export const handleSignIn: (email: string, password: string) => any  = async (email, password) => {
  await signOut().catch((err) => console.log('Error signing out: ', err));
  try {
    const user = await signIn({ username: email, password: password });
    if (user['isSignedIn'] === true) {
      store.dispatch(profileApi.util.resetApiState());
      return { isSuccess: true, status: 'success'}
    } else {
      if (user?.nextStep?.signInStep === "CONFIRM_SIGN_UP") {
        //setNonFormMessage({ status: 'error', type: 'emailNotConfirmed', message: " Please click the Verify Email link in the confirmation email has been sent to your email address, to complete your sign-up.", });
        return { status: 'error', type: 'emailNotConfirmed', message: " Please click the Verify Email link in the confirmation email has been sent to your email address, to complete your sign-up.", };        
      } else {        
        //setNonFormMessage({ status: 'error', type: 'apiMessage', message: `Sorry, we could not sign you in.  Next step:${user?.nextStep?.signInStep}`, });
        return { status: 'error', type: 'apiMessage', message: `Sorry, we could not sign you in.  Next step:${user?.nextStep?.signInStep}`, };        
      }
      //return { isError: true };
    }
  } catch (errorSignIn) {
    //setNonFormMessage({ status: 'error', type: 'apiMessage', message: `Sorry, we could not sign you in.  ${(errorSignIn instanceof Error) ? errorSignIn.message : ""}`, });
    return ({ status: 'error', type: 'apiMessage', message: `Sorry, we could not sign you in.  ${(errorSignIn instanceof Error) ? errorSignIn.message : ""}`, });
    //return { isError: true, Error: (errorSignIn instanceof Error) ? errorSignIn.message : "" };
  }

}



export const SignInUIEmail: React.FC<DeleteProfileProps> = ({ deleteProfile }) => {
  //const { error } = useAuthenticator()
  const [showPassword, setShowPassword] = useState(false);
  const [nonFormMessage, setNonFormMessage] = useState<LoginErrorInfo>();
  const methods = useForm<FormValuesEmailSignIn>();
  const { control, handleSubmit, formState: { errors }, getValues } = methods

  const [signInLoading, setSignInLoading] = useState(false)
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: any) => event.preventDefault();

  async function handleSignInWithEmail() { //handleSubmit(event) {
    //    event.preventDefault();
    const data = getValues()//const data = new FormData(event.currentTarget);
    try {
      // set loading to true before calling API
      setSignInLoading(true);
      const response = await handleSignIn(data.email, data.password);
      if (response?.status === 'success') {
        await getCurrentUser();
        if (!deleteProfile)
          navigate("/");
        else
          navigate("/DeleteProfile");
      } else {
        setNonFormMessage( response)
        setSignInLoading(false);
      }

    } catch (error) {
      // add error handling here   
      setSignInLoading(false);
      //console.log(error);
    }

  };


  const filteredErrors = Object.entries(errors).filter(([field]) => field !== 'emailNotConfirmed' && field !== 'email' && field !== 'password');

  const handleResendConfirmation = async () => {
    const data = getValues()
    ////console.log(awsConfig)
    try {
      const input = { // ResendConfirmationCodeRequest
        clientId: getAwsConfigForBase().aws_user_pools_web_client_id,//awsConfig.aws_user_pools_web_client_id,
        username: data.email
      };
      //console.log(input)
      //const outret =
      await resendSignUpCode(input);
      //setError('apiMessage', { type: 'success', message: 'Confirmation code resent successfully. Please check your email.' });
      setNonFormMessage({ status: 'info', type: 'apiMessage', message: 'Confirmation code resent successfully. Please check your email.' });
      //setNonFormMessage('Confirmation code resent successfully. Please check your email.')
    } catch (errorSignIn) {
      //setError('apiMessage', { type: 'failure', message: `Error resending confirmation code: ${(errorSignIn instanceof Error) ? errorSignIn.message : '' }`});
      setNonFormMessage({ status: 'error', type: 'apiMessage', message: `Error resending confirmation code: ${(errorSignIn instanceof Error) ? errorSignIn.message : ''}` });
      //setNonFormMessage(`Error resending confirmation code: ${(errorSignIn instanceof Error) ? errorSignIn.message : ''}`)
    }
  };

  return (
    <FormProvider {...methods}>
        <Stack
          direction="column"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
          sx={{ border: 1, borderColor: '#DEDEDE', borderRadius: 2, p: 6 }}
        >
          <Typography sx={{ fontWeight: 'bold' }} component="h1" variant="h5">
            Sign in  {deleteProfile && <>to delete your account data </>}
          </Typography>

          <Box  component="form" onSubmit={handleSubmit(() => { })} noValidate>
            <EmailPasswordFields />
          
            {(nonFormMessage?.type === 'emailNotConfirmed') && (
              <Stack direction="column">
                <Typography color="error" variant="caption">  {nonFormMessage?.message}        </Typography>
                <Button onClick={handleResendConfirmation} disableElevation variant="contained"
                  sx={{ backgroundColor: '#656EFF', mb: 1 }} >Resend Email Confirmation
                </Button>
              </Stack>
            )}
            {(nonFormMessage?.type === 'apiMessage') &&
              <Typography color={nonFormMessage?.status} variant="caption"> {nonFormMessage?.message}</Typography>
            }
            <LoadingButton        
              loading={signInLoading}
              fullWidth
              disableElevation
              variant="contained"
              onClick={handleSubmit(handleSignInWithEmail)}
              sx={{ mt: '20px', backgroundColor: 'black', borderRadius:8 }}
            >
              Sign In
            </LoadingButton>
            {Object.keys(errors).length > 0 && (
              <div>
                <ul>
                  {filteredErrors.map(([field, errorNonField]) => (
                    <li key={field}>
                      <Typography color="error" variant="body2">
                        {field}: {errorNonField.message}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Box>
          <Divider sx={{ width: '100%' }}>or</Divider>
          <LoadingButton
            loading={signInLoading}
            type="submit"
            fullWidth
            disableElevation
            variant="contained"
            sx={{ backgroundColor: '#0866FF', borderRadius:8 }}
            onClick={() => SignInWithFB(setSignInLoading, setNonFormMessage)/*signInWithRedirect({ provider: 'Amazon' })*/}
            startIcon={<SvgIcon component={FacebookIcon} inheritViewBox />}
          >
            <span>sign in with facebook</span>
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link sx={{ cursor: 'pointer' }}  onClick={ ()=> navigate( "/ForgotPassword")}  variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link sx={{ cursor: 'pointer' }} onClick={ ()=> navigate( "/SignUp")} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Stack>
    </FormProvider>)
}

export const EmailPasswordFields: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { getValues, control, formState: { errors } } = useFormContext<FormValuesEmailSignIn>();
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: any) => event.preventDefault();
  return (
    <>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{ required: 'Please enter your email', pattern: { value: /\S+@\S+\.\S+/, message: 'Invalid email format', }, }}
        render={({ field }) => (
          <TextField
            {...field}
            margin="normal"
            fullWidth
            id="email"
            label="Email"
            autoFocus
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
            autoComplete="email"
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{ required: 'Please enter your password' }}
        render={({ field }) => (
          <TextField
            {...field}
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ''}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>)
}
